<template>
	<div class="parkOrder">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 30px">
      <el-breadcrumb-item :to="{ path: '/parkLocation' }">园区选址</el-breadcrumb-item>
      <el-breadcrumb-item>订单</el-breadcrumb-item>
    </el-breadcrumb>		
		<div class="top">
			<div style="display: flex;">
				<el-input v-model="keyword" size="small" style="width: 399px;" placeholder="输入用户名称、公司、品牌进行搜索"></el-input>
				<el-button type="primary" icon="el-icon-search" size="small" style="margin-left:5px;" @click="search">搜索</el-button>
			</div>
		</div>
		<div class="table">
			<el-table :data="ordersList" border style="width: 100%">
				<el-table-column prop="company" align="center" label="入职企业名称"></el-table-column>
				<el-table-column align="center" label="租赁地址">
					<template slot-scope="scope">
						<el-select v-model="scope.row.site_selection_address_id" placeholder="请选择" @change="orderChange(scope.row)">
							<el-option v-for="item in addressList" :key="item.id" :label="item.address" :value="item.id" :v-if="!item.is_full && (item.status == 2)">
							</el-option>
						</el-select>
					</template>          
			</el-table-column>
					<el-table-column align="center" label="租赁时间" width="180">
						<template slot-scope="scope">
							{{scope.row.paid_at}}~{{scope.row.endTime}}
						</template>					
					</el-table-column>
					<el-table-column prop="money" align="center" label="出租总价" width="100">
						<template slot-scope="scope">
							{{scope.row.money}}元
						</template>						
					</el-table-column>
					<el-table-column prop="paid_at" align="center" label="支付日期" width="160"></el-table-column>
					<el-table-column align="center" label="支付状态" width="180">
						<template slot-scope="scope">
							<!-- 线上支付 -->
							<el-select v-if="scope.row.pay_type == 1" v-model="scope.row.model" placeholder="请选择" disabled>
								<el-option v-for="item in olOptions" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
							<!-- 线下支付 -->
							<el-select v-else v-model="scope.row.model" placeholder="请选择" @change="payModelChange(scope.row)" :disabled="scope.row.model == 5?true:false">
								<el-option v-for="item in ofOptions" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>						
						</template>
			</el-table-column>				
				<el-table-column label="联系买家" align="center" width="100">
					<template slot-scope="scope">
						<el-button type="primary" icon="el-icon-phone-outline" @click="editParkLocation(scope.row)"></el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pages">
				<el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-count="pageCount" layout="total,prev,pager,next,jumper"
				 :total="total">
				</el-pagination>
			</div>
		</div>
		<!-- 联系方式 -->
		<el-dialog title="买家联系方式" :visible.sync="dialogVisible" >
			<div style="font-size: 16px;margin: auto;">
				<div style="margin-bottom: 10px">买家姓名：<span>{{buyersName}}</span></div>
				<div>买家联系方式：<span>{{buyersPhone}}</span></div>
			</div>
			<div style="display:flex;justify-content: center;margin-top: 10px">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 线下支付时间 -->
		<el-dialog title="请选择线下支付时间" :visible.sync="dialogVisible2" >
			<el-form ref="timeForm" :model="timeForm" label-width="80px">
				<el-form-item label="支付日期">
					<el-date-picker
					v-model="paid_at"
					type="datetime"
					placeholder="选择日期时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">立即创建</el-button>
					<el-button>取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		name: 'parkOrder',
		data() {
			return {
        id: '',
				site_selection_id: '',				
				keyword: '',
				ordersList: [],
				total: 0,
				page: 1,
				limit: 10,
				pageCount: Math.ceil(this.total / 10),
				olOptions: [
					{label: '线上未支付', value: 1},
					{label: '线上已支付', value: 2},
					{label: '支付超时', value: 3},
				],
				ofOptions: [
					{label: '线下未支付', value: 4},
					{label: '线下已支付', value: 5},
				],
				timeForm: {},
				addressList: [],
				dialogVisible: false, // 弹窗
				dialogVisible2: false,
				buyersName: '',
				buyersPhone: '',
				paid_at: '',
				isOrder: '',
			}
    },
		mounted() {
			this.id = sessionStorage.organizationId;
			this.getParkLocation();
		},
		methods: {
			// 获取地址数据
			getParkLocation() {
				this.$http.get(this.URL.adminUrl.siteOrders + this.site_selection_id, {
					params: {
						keyword: this.keyword,
					}	
				}).then((res) => {
					this.ordersList = res.data.data.data;
					for(let i in this.ordersList){
						let time = this.ordersList[i].paid_at
						let timeArray = time.split("-")
						let newYear = Number(timeArray[0]) + Number(this.ordersList[i].order_year) 
						this.ordersList[i]['endTime'] = newYear + '-' +  timeArray[1] + '-' + timeArray[2]
						if(pay_type == 1){ //线上
							if(pay_status){
								this.ordersList[i]['model'] = 2
							}else{
								if(Date.now() > new Date(this.ordersList[i].paid_at)) {
									this.ordersList[i]['model'] = 3
								}else{
									this.ordersList[i]['model'] = 1
								}
							}
						}else{ //线下
							if(pay_status){
								this.ordersList[i]['model'] = 5
							}else{
								this.ordersList[i]['model'] = 4
							}
						}
					}	
					this.total = res.data.data.meta.total;
				})
			},
			// 搜索
			search() {
				this.page = 1;
				this.getParkLocation();
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getParkLocation();
			},
			// 联系买家
			editParkLocation(row) {
				this.dialogVisible = true;
				this.buyersName = row.user_name
				this.buyersPhone = row.user_mobile
			},
			// 租赁地址	
			orderChange(row) {
				// console.log(row)
				this.$http.post(this.URL.adminUrl.orderAddress + row.id, {
					site_selection_address_id: row.site_selection_address_id
				}).then((res) => {
					this.getParkLocation();
				})
			},
			// 线下支付方式更改
			payModelChange(row) {
				// console.log(row)
				dialogVisible2 = true;
				this.isOrder = row.id
			},
			onSubmit() {
				this.$http.post(this.URL.adminUrl.orderPayStatus + row.id, {
					pay_status: true,
					paid_at: this.paid_at,
				}).then((res) => {
					dialogVisible2 = false
					this.getParkLocation();
				})				
			}
		},
		created() {
			this.site_selection_id = this.$route.params.data.id

			this.$http.get(this.URL.adminUrl.orderAddress, {
				params: {
					site_selection_id: this.site_selection_id,
				}
			}).then((res) => {
				this.addressList = res.data.data
			})			
		}
	}
</script>
<style scoped>
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.table {
		margin-top: 30px;
	}
	.pages {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 50px 0;
	}
</style>
